import { useEffect, useState } from 'react';
import Web3 from 'web3';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import './App.css';

const vestingABI = require('./contracts/TokenVesting.json');
const VESTING_CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: '90043e1e642e45489917e5f18a4b6cc7',
    },
  },
};

const web3Modal = new Web3Modal({
  network: process.env.REACT_APP_NETWORK,
  providerOptions,
  theme: 'dark',
});

function App() {
  const [web3, setWeb3] = useState(null);
  const [account, setAccount] = useState('');
  const [connectWallet, setConnectWallet] = useState(false);
  const [claimableAmount, setClaimableAmount] = useState(0);
  const [claimedAmount, setClaimedAmount] = useState(0);
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [wasRevoked, setWasRevoked] = useState(false);
  const [grantAmount, setGrantAmount] = useState(0);

  const queryAmounts = async () => {
    const vestingContract = new web3.eth.Contract(
      vestingABI,
      VESTING_CONTRACT_ADDRESS
    );
    const data = await vestingContract.methods
      .getGrantInfo(account, today())
      .call();
    console.log(data);

    const {
      amountAvailable,
      amountClaimed,
      amountOfGrant,
      amountNotVested,
      wasRevoked,
    } = data;

    const claimableAmount = web3.utils.fromWei(amountAvailable);
    const claimedAmount = web3.utils.fromWei(amountClaimed);
    const grantAmount = web3.utils.fromWei(amountOfGrant);
    const remainingAmount = web3.utils.fromWei(amountNotVested);
    setClaimableAmount(claimableAmount);
    setClaimedAmount(claimedAmount);
    setGrantAmount(grantAmount);
    setRemainingAmount(remainingAmount);
    setWasRevoked(wasRevoked);
  };

  useEffect(() => {
    if (web3 && account) {
      queryAmounts();
    }
  }, [web3, account]);

  const today = () => {
    return Math.floor(Date.now() / 1000 / 86400);
  };

  const onWalletConnect = async () => {
    setConnectWallet(true);
    const provider = await web3Modal.connect();

    // Subscribe to accounts change
    provider.on('accountsChanged', (accounts) => {
      console.log(accounts);
      setAccount(accounts[0]);
    });

    // Subscribe to chainId change
    provider.on('chainChanged', (chainId) => {
      console.log(chainId);
    });

    // Subscribe to provider connection
    provider.on('connect', (info) => {
      console.log(info);
    });

    // Subscribe to provider disconnection
    provider.on('disconnect', (error) => {
      console.log(error);
      setWeb3(null);
    });

    const web3 = new Web3(provider);
    setWeb3(web3);

    const account = await web3.eth.getAccounts();
    setAccount(account[0]);

    setConnectWallet(false);
  };

  const onDisconnectClick = () => {
    setWeb3(null);
  };

  const onClaim = () => {
    const vestingContract = new web3.eth.Contract(
      vestingABI,
      VESTING_CONTRACT_ADDRESS
    );

    const result = vestingContract.methods
      .claimVestingTokens(account)
      .send({ from: account });

    console.log(result);
    queryAmounts();
  };

  return (
    <div className='App'>
      <header className='App-header'>
        <h3 className='mb-3'>Paribus Vesting Portal</h3>
        {web3 ? (
          <>
            <span>{`Connected wallet: ${account}`}</span>
            <button
              type='button'
              className='btn btn-primary'
              onClick={onDisconnectClick}
            >
              Disconnect
            </button>
          </>
        ) : (
          <button
            disabled={connectWallet}
            type='button'
            className='btn btn-primary'
            onClick={onWalletConnect}
          >
            Connect your wallet
          </button>
        )}
        {web3 && account ? (
          <>
            <div className='container mt-5'>
              <div className='row'>
                <div className='col-6'>Claimable Amount:</div>
                <div className='col-6'>{`${claimableAmount} PBX`}</div>
                <div className='col-6'>Already claimed Amount:</div>
                <div className='col-6'>{`${claimedAmount} PBX`}</div>

                <div className='col-6'>Still vested Amount:</div>
                {wasRevoked ? (
                  <div className='col-6'>Paused</div>
                ) : (
                  <div className='col-6'>{`${remainingAmount} PBX`}</div>
                )}
                <div className='col-6'>Total Vested:</div>
                <div className='col-6'>{`${grantAmount} PBX`}</div>
              </div>
            </div>
            <button
              disabled={claimableAmount <= 0}
              type='button'
              className='btn btn-primary mt-3'
              onClick={onClaim}
            >
              Claim
            </button>
          </>
        ) : null}
      </header>
    </div>
  );
}

export default App;
